<template>
  <div>
    <div class="mb-3 d-flex align-center justify-space-between">
      <div class="w-40per">
        <select-pos-model
          v-model="idPos"
          :label="$t('labels.pos')"
          custom-class="mr-2"
          @change="focusInputQr"
        ></select-pos-model>
      </div>
      <div class="w-60per">
        <input-qr-scan-model
          ref="inputQr"
          v-model="code"
          :label="label"
          @keyupEnter="inputScan"
        ></input-qr-scan-model>
      </div>
    </div>

    <div>
      <v-simple-table class="table-padding-2-no-top">
        <tbody>
          <tr>
            <td class="font-weight-medium" style="width: 70px">Sọt:</td>
            <td
              colspan="2"
              style="font-size: 20px"
              class="primary--text font-weight-medium"
            >
              {{ basket.code }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Tên:</td>
            <td>{{ goods.name }}</td>
            <td
              rowspan="6"
              style="
                width: 98px;
                border-bottom: 0;
                vertical-align: top;
                padding-top: 16px;
              "
              class="text-center"
            >
              <ImageViewer
                width="80px"
                height="auto"
                v-if="goods.url_images"
                :url="goods.url_images"
              />
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Đặc tả:</td>
            <td>{{ goods.description }}</td>
          </tr>
          <tr>
            <td class="font-weight-medium">Size:</td>
            <td>{{ goods.size }}</td>
          </tr>
          <tr>
            <td class="font-weight-medium">Barcode:</td>
            <td>{{ goods.customer_goods_barcode }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <v-dialog v-model="expiredDateDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="text-h6"> HSD </v-card-title>

        <v-card-text>
          <v-row dense>
            <v-col cols="6">
              <v-autocomplete
                v-model="goods.year"
                :items="yearOptions"
                class="c-input-xs"
                :label="$t('labels.expired_year')"
                dense
                outlined
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="goods.month"
                :items="monthOptions"
                class="c-input-xs"
                :label="$t('labels.expired_month')"
                dense
                outlined
                clearable
                hide-details
                :disabled="!goods.year"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="goods.day"
                :items="dayOptions"
                :disabled="!goods.month || !goods.year"
                class="c-input-xs"
                :label="$t('labels.expired_day')"
                dense
                outlined
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="goods.lot_tracking"
                class="c-input-xs"
                type="text"
                :label="$t('labels.lot')"
                dense
                outlined
                clearable
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="expiredDateDialog = false">
            {{ $t("labels.close") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmReceiptWithExpiredDate"
          >
            {{ $t("labels.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import moment from "moment/moment";
const CURRENT_YEAR = moment().year();
const CURRENT_MONTH = moment().month() + 1;
const CURRENT_DAY = moment().date();

export default {
  name: "ReceiptDirect",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  data: () => ({
    isLoading: false,
    idPos: null,
    step: 1,
    code: null,
    items: [],
    posOptions: [],
    goods: {},
    basket: {},
    expiredDateDialog: false,
  }),
  computed: {
    label() {
      switch (this.step) {
        case 1:
          return this.$t("labels.basket_code");
        case 2:
          return "Barcode / UID";
        default:
          return "";
      }
    },
    dayOptions() {
      if (!this.goods.year || !this.goods.month) {
        return [];
      }
      const dayFrom =
        this.year == CURRENT_YEAR && +this.month == CURRENT_MONTH
          ? CURRENT_DAY
          : 1;
      const days = [];
      for (let i = dayFrom; i <= 31; i++) {
        const dayVal = i < 10 ? `0${i}` : `${i}`;
        const checkDay = moment(
          `${this.goods.year}-${this.goods.month}-${dayVal}`
        );
        if (checkDay.isValid()) {
          days.push({
            value: dayVal,
            text: dayVal,
          });
        }
      }
      return days;
    },
    monthOptions() {
      if (!this.goods.year) {
        return [];
      }
      const months = [];
      const monthFrom = this.goods.year == CURRENT_YEAR ? CURRENT_MONTH : 1;
      for (let i = monthFrom; i <= 12; i++) {
        const monthVal = i < 10 ? `0${i}` : `${i}`;
        months.push({
          value: monthVal,
          text: monthVal,
        });
      }
      return months;
    },
    yearOptions() {
      let years = [];
      for (let i = CURRENT_YEAR; i < CURRENT_YEAR + 9; i++) {
        years.push({
          value: `${i}`,
          text: `${i}`,
        });
      }
      return years;
    },
  },
  mounted() {
    this.getPos();
  },
  methods: {
    focusInputQr() {
      this.$refs.inputQr.focusInput();
    },
    getPos() {
      const { poss } = window.me;
      this.posOptions = [...poss].map((w) => ({
        value: w.id,
        text: w.code || "",
      }));
      if (this.posOptions.length === 1) {
        this.idPos = this.posOptions[0].value;
      }
    },
    inputScan() {
      if (!this.code) {
        return false;
      }

      switch (this.step) {
        case 1:
          return this.scanBasket();
        case 2:
          return this.scanGoodsAndReceipt();
        default:
          this.$vToastify.error("Wrong!");
      }
    },
    async printStampAgain(id_goods, insert_tracking) {
      await this.downloadExcelFile(
        `${process.env.VUE_APP_API_V2_URL}/pos-generate-goods-stamp`,
        {
          id_goods: id_goods,
          insert_tracking: insert_tracking,
        },
        `50x20_${insert_tracking}.pdf`
      );
    },
    async getGoods() {
      const { data } = await httpClient.post("/get-goods-by-barcode", {
        customer_goods_barcode: this.code,
      });
      return data;
    },
    async receiptDirectOne() {
      const { data } = await httpClient.post("/pos-receipt-direct-one", {
        id_goods: this.goods.id,
        id_basket: this.basket.id,
        quantity: 1,
        code: this.code || this.goods.sku || null,
        id_pos: this.idPos,
        expired_date: this.goods.process_exp_date
          ? `${this.goods.year}-${this.goods.month}-${this.goods.day}`
          : null,
        lot_tracking: this.goods.lot_tracking || null,
      });
      return data;
    },
    async confirmReceiptWithExpiredDate() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const stamp = await this.receiptDirectOne();
        if (!this.code.includes("@") || stamp.is_new) {
          await this.printStampAgain(this.goods.id, stamp.insert_tracking);
        }
        this.code = null;
        this.isLoading = false;
        this.expiredDateDialog = false;
        this.goods = {};
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        this.isLoading = false;
        await this.scanBasket();
      }
    },
    async scanGoodsAndReceipt() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const goods = await this.getGoods();
        this.goods = { ...goods };
        if (this.goods.process_exp_date) {
          this.expiredDateDialog = true;
          this.isLoading = false;
          return false;
        }
        const stamp = await this.receiptDirectOne();
        if (!this.code.includes("@") || stamp.is_new) {
          await this.printStampAgain(this.goods.id, stamp.insert_tracking);
        }
        this.code = null;
        this.isLoading = false;
        this.goods = {};
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        /*const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null*/
        this.isLoading = false;
        await this.scanBasket();
      }
    },
    async scanBasket() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/pos-get-basket-for-receipt", {
          code: this.code,
        });
        this.basket = { ...data };
        this.step = 2;
        this.code = null;
        this.isLoading = false;
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
  },
};
</script>

<style scoped></style>
